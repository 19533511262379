import CommonQuestion from "../../domain/entities/commonQuestion"
import { AxiosHttp } from "../../http/axios"
import FAQRepository from "../repository/faqRepository"

export default class FAQProvider {
    private static instance: FAQProvider
    private faqRepository: FAQRepository
    authErrors: { email: string, password: string } = { email: '', password: '' }

    private constructor() {
        this.faqRepository = FAQRepository.getInstance(AxiosHttp.getInstance())
    }

    static getInstance(): FAQProvider {
        if (!FAQProvider.instance) {
            FAQProvider.instance = new FAQProvider()
        }
        return FAQProvider.instance
    }

    async createCommonQuestion(commonQuestion: CommonQuestion) {
        return await this.faqRepository.createCommonQuestion(commonQuestion)
    }

    async getCommonQuestions(): Promise<CommonQuestion[]> {
        return await this.faqRepository.getCommonQuestions()
    }

    async getCommonQuestionById(id: string): Promise<CommonQuestion> {
        return await this.faqRepository.getCommonQuestionById(id)
    }

    async updateCommonQuestion(commonQuestion: CommonQuestion) {
        return await this.faqRepository.updateCommonQuestion(commonQuestion)
    }

    async deleteCommonQuestion(commonQuestionId: string) {
        return await this.faqRepository.deleteCommonQuestion(commonQuestionId)
    }
}