export default class PropertyQuiz {
    constructor(
        public value: number | undefined = undefined,
        public isOwned: boolean | undefined = undefined,
        public isThirdParty: boolean | undefined = undefined,
        public isFinanced: boolean | undefined = undefined,
        public wantsRefinance: boolean | undefined = undefined,
        public refinanceValue: number | undefined = undefined,
        public thirdPartyDocument: string | undefined = undefined,
        public useAsPayment: boolean | undefined = undefined,
        public cpf: string | undefined = undefined,
    ) {}
}