import React, { useState } from "react";

// Supondo que esta classe já tenha sido definida
export class UnitStatus {
  public readonly AVAILABLE = "available";
  public readonly UNAVAILABLE = "not_available";
  public readonly RESERVED = "reserved";
  public readonly SOLD = "sold";

  public readonly ALL = [
    this.AVAILABLE,
    this.UNAVAILABLE,
    this.RESERVED,
    this.SOLD,
  ];

  public unitStatusMap: Record<string, string> = {
    available: "Ativo",
    not_available: "Inativo",
    reserved: "Reservado",
    sold: "Vendido",
    unknown: "Desconhecido",
  };

  constructor(public unitStatus: string) {
    this.unitStatus = this.ALL.includes(unitStatus) ? unitStatus : "unknown";
  }

  public getStatus(): string {
    return this.unitStatus;
  }

  public getFormatted(): string {
    return this.unitStatusMap[this.unitStatus];
  }
}

// Componente React
const UnitStatusSelect = () => {
  const [unitStatus, setUnitStatus] = useState(new UnitStatus("available")); // Estado inicial do status

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Altera o status baseado na seleção
    const newStatus = event.target.value;
    setUnitStatus(new UnitStatus(newStatus)); // Cria uma nova instância do UnitStatus
  };
};

export default UnitStatus;
