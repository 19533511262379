export default class CommonQuestion {
    constructor (
        public readonly id: string,
        public readonly name: string,
        public readonly question: string,
        public readonly answer: string,
        public readonly type: string,
        public readonly helpfulVotes: number,
    ) {}

}