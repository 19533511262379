import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/components/_empreedimentosdetalhes.scss";
import {
  ChevronLeft,
  Edit2,
  EyeIcon,
  Upload,
  Trash2,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  X,
} from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "../../components/tootip/Tootip";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../../assets/images/casa1.jpg";
import image2 from "../../assets/images/casa2.jpg";
import image3 from "../../assets/images/casa3.jpg";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import Enterprise from "../../domain/entities/enterprise";
import EnterpriseStatus from "../../domain/entities/valueObjects/enterpriseStatus";
import ReleaseTypeProvider from "../../infra/provider/releaseTypeProvider";
import ProjectPlanProvider from "../../infra/provider/projectPlanProvider";
import ProjectPlan from "../../domain/entities/projectPlan";
import UnitProvider from "../../infra/provider/unitProvider";
import Unit from "../../domain/entities/unit";
import { Formatter } from "../../utils/formatter";
import UploadImageProvider from "../../infra/provider/uploadImageProvider";
import { set } from "lodash";
import UnitStatus from "../../domain/entities/valueObjects/unitStatus";
import Room from "../../domain/entities/valueObjects/room";

const photos = [image1, image2, image3, image1, image2, image3];

export function EnterprisesDetails() {
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<{
    title: string;
    tags: string[];
  }>({ title: "", tags: [] });
  const isFetching = useRef(false);
  const [enterprise, setEnterprise] = useState<Enterprise>(new Enterprise());
  const [unitForm, setUnitForm] = useState(new Unit());
  const [projectPlanImage, setProjectPlanImage] = useState<{
    file: File | null;
    progress: number;
    url?: string;
  }>({ file: null, progress: 0 });
  const [newTag, setNewTag] = useState("");
  const enterpriseProvider = EnterpriseProvider.getInstance();
  const releaseTypeProvider = ReleaseTypeProvider.getInstance();
  const projectPlanProvider = ProjectPlanProvider.getInstance();
  const unitProvider = UnitProvider.getInstance();
  const uploadImageProvider = UploadImageProvider.getInstance();
  const { id } = useParams();

  useEffect(() => {
    getEnterpriseDetails();
  }, []);

  const getEnterpriseDetails = async () => {
    if (isFetching.current) return;
    try {
      isFetching.current = true;
      const response = await enterpriseProvider.getEnterpriseById(id as string);
      setEnterprise(response);
    } catch (error) {
      console.error(error);
    }
    isFetching.current = false;
  };

  const toggleDescription = () => setDescriptionOpen(!descriptionOpen);
  const showTagsModal = (category: string) => {
    const tagsByCategory = {
      Tags: enterprise.tags,
      Serviços: enterprise.services,
      "Areas Comuns": enterprise.commonAreas,
    };
    setModalContent({
      title: category,
      tags: tagsByCategory[category as keyof typeof tagsByCategory],
    });
    setModalOpen(true);
  };

  const openModal = (title: string, tags: string[]) => {
    setModalContent({ title, tags });
    setModalOpen(true);
  };

  const handleAddTag = () => {
    if (newTag.trim() === "") return;
    const updatedTags = [...modalContent.tags, newTag];
    updateTags(modalContent.title, updatedTags);
    setModalContent({ ...modalContent, tags: updatedTags });
    setNewTag("");
  };

  const handleRemoveTag = (index: number) => {
    const updatedTags = modalContent.tags.filter((_, i) => i !== index);
    updateTags(modalContent.title, updatedTags);
    setModalContent({ ...modalContent, tags: updatedTags });
  };

  const updateTags = (category: string, updatedTags: string[]) => {
    const updatedEnterprise = { ...enterprise };
    if (category === "Tags") updatedEnterprise.tags = updatedTags;
    if (category === "Serviços") updatedEnterprise.services = updatedTags;
    if (category === "Areas Comuns") updatedEnterprise.commonAreas = updatedTags;
    setEnterprise(updatedEnterprise);
  };

  const [isPlantModalOpen, setIsPlantModalOpen] = useState(false);
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleCreatePlant = async (e: React.FormEvent<HTMLFormElement>) => {
    if (projectPlanImage.file && projectPlanImage.progress < 100) {
      return toast.error("Por favor, espere o upload da imagem terminar");
    }

    e.preventDefault();
    setIsLoading(true);

    try {
      projectPlanForm.images = [{ imageLink: projectPlanImage.url! }];
      await projectPlanProvider.createEnterpriseProjectPlan(
        enterprise.id,
        projectPlanForm
      );
      toast.success("Planta criada com sucesso!");
      setIsPlantModalOpen(false);
      setProjectPlanForm(new ProjectPlan());
      setProjectPlanImage({ file: null, progress: 0 });
      getEnterpriseDetails();
    } catch (error) {
      toast.error("Erro ao criar planta");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClosePlantModal = () => {
    setIsPlantModalOpen(false);
    setProjectPlanForm(new ProjectPlan());
    toast.info("Não se preocupe, nenhuma informação foi salva!");
  };

  const handleCloseUnitModal = () => {
    setIsUnitModalOpen(false);
    setUnitForm(new Unit());
    toast.info("Não se preocupe, nenhuma informação foi salva!");
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/empreendimentos/visualizar");
  };

  const handleCreateUnit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await unitProvider.createEnterpriseUnit(enterprise.id, unitForm);
      toast.success("Unidade criada com sucesso!");
      setIsUnitModalOpen(false);
      getEnterpriseDetails();
    } catch (error) {
      toast.error("Erro ao criar unidade");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateEnterpriseStatus = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.preventDefault();
    const status = new EnterpriseStatus(e.target.value);
    try {
      await enterpriseProvider.updateEnterpriseStatus(enterprise.id, status);
      setEnterprise((prevEnterprise) => ({
        ...prevEnterprise,
        status: status,
      }));
      toast.success("Status alterado com sucesso!");
    } catch (error) {
      toast.error("Erro ao alterar status do empreendimento");
    }
  };

  const handleUpdateEnterpriseReleaseType = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    e.preventDefault();
    const releaseTypeId = e.target.value;
    try {
      enterpriseProvider.updateEnterpriseReleaseType(
        enterprise.id,
        releaseTypeId
      );
      const releaseType = releaseTypeProvider.getReleaseTypeById(releaseTypeId);
      setEnterprise((prevEnterprise) => ({
        ...prevEnterprise,
        releaseType: releaseType!,
      }));
      toast.success("Estágio alterado com sucesso!");
    } catch (error) {
      toast.error("Erro ao alterar estágio do empreendimento");
    }
  };

  const handleProjectPlanFileChange = (files: FileList) => {
    const file = files[0];
    setProjectPlanImage({ file, progress: 0 });
    uploadProjectPlanImage(file);
  };

  const [isEditUnitModalOpen, setIsEditUnitModalOpen] = useState(false);

  const handleEditUnit = (unit: any) => {
    setSelectedUnit(unit);
    setUnitForm(unit);
    setIsEditUnitModalOpen(true);
  };

  const handleDeleteUnit = (unit: any) => {
    setSelectedUnit(unit.id);
    setIsDeleteModalUnitOpen(true);
  };

  const handleCloseEditUnitModal = () => {
    setIsEditUnitModalOpen(false);
    setSelectedUnit(null);
  };

  const confirmDeleteUnits = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await unitProvider.deleteUnit(unitForm.id);
      toast.success("Unidade excluída com sucesso!");
    } catch (error) {
      toast.error("Não foi possivel excluir a unidade.");
    } finally {
      setIsDeleteModalUnitOpen(false);
      setSelectedUnit(null);
      getEnterpriseDetails();
      setIsLoading(false);
    }
    setIsEditUnitModalOpen(false);
  };

  const handleSaveUnitChanges = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await unitProvider.updateUnit(unitForm);
      toast.success("Status da unidade atualizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar status da unidade");
    } finally {
      getEnterpriseDetails();
      setIsLoading(false);
    }
    setIsEditUnitModalOpen(false);
  };

  const uploadProjectPlanImage = async (file: File) => {
    try {
      setProjectPlanImage((prevImage) => ({ ...prevImage, progress: 0 }));
      const imageUrl = await uploadImageProvider.upload(file);
      if (imageUrl) {
        setProjectPlanImage((prevImage) => ({
          ...prevImage,
          progress: 100,
          url: imageUrl,
        }));
      }
    } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      toast.error(`Erro ao fazer upload de ${file.name}`);
    }
  };

  const [toggleState, setToggleState] = useState(false);
  const handleDeleteProjectPlanImage = () => {
    setProjectPlanImage({ file: null, progress: 0 });
  };

  const handleDropProjectPlanImage = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      handleProjectPlanFileChange(e.dataTransfer.files);
    }
  };

  const handleAddPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newPhotoUrl = URL.createObjectURL(file);
      setEnterprise((prev: any) => ({
        ...prev,
        images: [...prev.images, { path: newPhotoUrl }],
      }));
      toast.success("Foto adicionada com sucesso!");
    }
  };

  const handleFileInputClick = () => {
    const fileInput = document.getElementById("add-photo") as HTMLInputElement;
    fileInput?.click();
  };

  const handleDeletePhoto = (index: number) => {
    setEnterprise((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
    if (currentPhotoIndex >= index && currentPhotoIndex > 0) {
      setCurrentPhotoIndex((prev) => prev - 1);
    }
    toast.success("Foto excluída com sucesso!");
  };

  const handleEditPhoto = (index: number) => {
    const inputElement = document.getElementById(`edit-photo-${index}`) as HTMLInputElement;
    inputElement?.click();
  };

  const handleEditFile = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0];
    if (file) {
      const updatedPhotoUrl = URL.createObjectURL(file);
      setEnterprise((prev: any) => {
        const updatedImages = [...prev.images];
        updatedImages[index].path = updatedPhotoUrl;
        return { ...prev, images: updatedImages };
      });
      toast.success("Foto atualizada com sucesso!");
    }
  };

  const scrollContainer = document.querySelector('.small-photos-scroll');
  if (scrollContainer && enterprise.images.length > 5) {
    scrollContainer.setAttribute('data-remaining', String(enterprise.images.length - 4));
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectPlanForm, setProjectPlanForm] = useState(new ProjectPlan());
  const [selectedPlan, setSelectedPlan] = useState<ProjectPlan | undefined>(undefined);
  const [isDeleteModalUnitOpen, setIsDeleteModalUnitOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);

  const handleDeletePlants = (id: any) => {
    setSelectedPlan(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDeletePlants = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await projectPlanProvider.deleteProjectPlan(`${selectedPlan}`);
      toast.success("Planta excluída com sucesso!");
    } catch (error) {
      toast.error("Não foi possivel excluir a planta pois ela já está vinculada a uma unidade.");
    } finally {
      setSelectedUnit(null);
      getEnterpriseDetails();
      setIsLoading(false);
      setIsDeleteModalOpen(false);
    }
  };

  const handleCancelDeletePlants = () => {
    setIsDeleteModalOpen(false);
    //setSelectedPlan(null);
    toast.info("Não se preocupe, nenhuma planta foi deletada!");
  };

  const handleCancelDeleteUnits = () => {
    setIsDeleteModalUnitOpen(false);
    setSelectedUnit(null);
    toast.info("Não se preocupe, nenhuma planta foi deletada!");
  };

  const [units, setUnits] = useState<Unit[]>([]);

  useEffect(() => {
    if (enterprise.units) {
      setUnits(enterprise.units);
    }
  }, [enterprise.units]);

  const handleStatusChange = (unitId: string, newStatus: string) => {
    setUnits((prevUnits) => {
      const updatedUnits = prevUnits.map((unit) =>
        unit.id === unitId
          ? { ...unit, status: new UnitStatus(newStatus) }
          : unit
      );
      return updatedUnits;
    });
    handleUpdateUnit(unitId, newStatus);
  };

  const handleUpdateUnit = async (unitId: string, newStatus: string) => {
    setIsLoading(true);
    try {
      await unitProvider.updateUnitStatus(unitId, newStatus);
      toast.success("Status da unidade atualizado com sucesso!");
    } catch (error) {
      toast.error("Erro ao atualizar status da unidade");
    } finally {
      getEnterpriseDetails();
      setIsLoading(false);
    }
  };

  const [unitStatus, setUnitStatus] = useState(new UnitStatus("available"));


  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleEditPlant = (plan: any) => {
    setSelectedPlan(plan);
    setIsEditModalOpen(true);
  };

  const handleSavePlantChanges = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await projectPlanProvider.updateProjectPlan(selectedPlan!);
      toast.success("Planta editada com sucesso!");
    } catch (error) {
      toast.error("Erro ao editada a planta.");
    } finally {
      getEnterpriseDetails();
      setIsLoading(false);
      setIsEditModalOpen(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: keyof Room
  ) => {
    const value = Number(e.target.value);

    setProjectPlanForm((prevState) => ({
      ...prevState,
      rooms: prevState.rooms.map((room, i) =>
        i === index ? { ...room, [field]: value } : room
      ),
    }));
  };

  const handleEditInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    field: keyof Room
  ) => {
    const value = Number(e.target.value);

    // Atualiza o estado de selectedPlan
    setSelectedPlan((prevState) => prevState && {
      ...prevState,
      rooms: prevState.rooms.map((room, i) =>
        i === index ? { ...room, [field]: value } : room
      ),
    });
  };

  return (
    <div className="property-details">
      <div className="header-section">
        <div className="header-title">
          <ChevronLeft
            size={16}
            onClick={handleBackClick}
            className="back-arrow"
            style={{ cursor: "pointer" }}
          />
          <h1>Detalhes do empreendimento:</h1>
        </div>
        <div className="header-filters">
          <select
            className="filter-select"
            value={enterprise.status.getStatus()}
            onChange={handleUpdateEnterpriseStatus}
          >
            <option value="">{enterprise.status.getFormatted()}</option>
            {enterprise.status.ALL.filter(
              (status) => status !== enterprise.status.getStatus()
            ).map((status) => (
              <option key={status} value={status}>
                {enterprise.status.enterpriseStatusMap[status]}
              </option>
            ))}
          </select>
          <select
            className="filter-select"
            value={enterprise.releaseType.id}
            onChange={handleUpdateEnterpriseReleaseType}
          >
            <option value="">{enterprise.releaseType.name}</option>
            {releaseTypeProvider
              .getReleaseTypes()
              .filter((rt) => rt.id !== enterprise.releaseType.id)
              .map((rt) => (
                <option key={rt.id} value={rt.id}>
                  {rt.name}
                </option>
              ))}
          </select>
        </div>
      </div>

      {/* Sessão: Informações gerais */}
      <div className="info-characteristics-section">
        <div className="general-info">
          <h2>Informações gerais:</h2>
          <div className="info-box">
            <div className="info-row">
              <div className="info-item">
                <label>Nome:</label>
                <span>{enterprise.name}</span>
              </div>
              <div className="info-item">
                <label>Tipo de lançamento:</label>
                <span>{enterprise.releaseType.name}</span>
              </div>
            </div>
            <div className="divisor-enterprise"></div>
            <div className="info-row">
              <div className="info-item">
                <label>Categoria:</label>
                <span>{enterprise.category.name}</span>
              </div>
              <div className="info-item">
                <label>Construtora:</label>
                <span>{enterprise.constructionCompany.name}</span>
              </div>
            </div>
            <div className="divisor-enterprise"></div>
            <div className="info-row description">
              <div className="description-header" onClick={toggleDescription}>
                <label>Descrição:</label>
                {descriptionOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              {descriptionOpen && <p>{enterprise.description}</p>}
            </div>
            <div className="divisor-enterprise"></div>
            <div className="info-row">
              <div className="info-item">
                <label>CEP:</label>
                <span>{enterprise.address.zipCode.getCodeFormatted()}</span>
              </div>
              <div className="info-item">
                <label>Cidade:</label>
                <span>{enterprise.address.city}</span>
              </div>
            </div>
            <div className="divisor-enterprise"></div>
            <div className="info-row">
              <div className="info-item">
                <label>Logradouro:</label>
                <span>{enterprise.address.street}</span>
              </div>
              <div className="info-item">
                <label>Número:</label>
                <span>{enterprise.address.number}</span>
              </div>
            </div>
            <div className="divisor-enterprise"></div>
            <div className="info-row">
              <div className="info-item">
                <label>Bairro:</label>
                <span>{enterprise.address.neighborhood}</span>
              </div>
              <div className="info-item">
                <label>Estado:</label>
                <span>{enterprise.address.uf}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Seção Características */}
        <div className="characteristics">
          <h2>Características:</h2>
          <div className="characteristics-box">
            <div className="tag-section">
              <div className="tag-header">
                <h3>Tags:</h3>
                <button
                  onClick={() => showTagsModal("Tags")}
                  className="see-more"
                >
                  Ver mais
                </button>
              </div>
              <div className="tags-grid">
                {enterprise.tags.map((tag, i) => (
                  <span key={i} className="tag">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <div className="divisor-enterprise"></div>
            <div className="tag-section">
              <div className="tag-header">
                <h3>Serviços:</h3>
                <button
                  onClick={() => showTagsModal("Serviços")}
                  className="see-more"
                >
                  Ver mais
                </button>
              </div>
              <div className="tags-grid">
                {enterprise.services.map((service, i) => (
                  <span key={i} className="tag">
                    {service}
                  </span>
                ))}
              </div>
            </div>
            <div className="divisor-enterprise"></div>
            <div className="tag-section">
              <div className="tag-header">
                <h3>Áreas comuns:</h3>
                <button
                  onClick={() => showTagsModal("Areas Comuns")}
                  className="see-more"
                >
                  Ver mais
                </button>
              </div>
              <div className="tags-grid">
                {enterprise.commonAreas.map((area, i) => (
                  <span key={i} className="tag">
                    {area}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Seção Plantas */}
      <div className="plants-units-section">
        <div className="plants">
          <div className="section-header">
            <h2>Plantas:</h2>
            <button
              className="add-button"
              onClick={() => setIsPlantModalOpen(true)}
            >
              + Adicionar planta
            </button>
          </div>
          <div className="table-box-enterprise">
            <table className="table-box-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Área</th>
                  <th>Área construída</th>
                  <th>Quantidade de cômodos</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {enterprise.projectPlans.map((plan) => (
                  <tr key={plan.id}>
                    <td>{plan.id}</td>
                    <td>{plan.name}</td>
                    <td>{plan.area}m²</td>
                    <td>{plan.builtArea}m²</td>
                    <td>{plan.rooms.reduce((sum, room) => sum + room.quantity, 0)}</td>
                    <td>
                      <Tooltip text="Editar">
                        <button
                          className="icon-button"
                          onClick={() => handleEditPlant(plan)}>
                          <Edit2 size={18} />
                        </button>
                      </Tooltip>
                      <Tooltip text="Excluir">
                        <button
                          className="icon-button"
                          onClick={() => handleDeletePlants(plan.id)}
                        >
                          <Trash2 size={18} />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Seção Unidades */}
        <div className="units">
          <div className="section-header">
            <h2>Unidades:</h2>
            <button
              className="add-button"
              onClick={() => setIsUnitModalOpen(true)}
            >
              + Adicionar unidade
            </button>
          </div>
          <div className="table-box-enterprise">
            <table className="table-box-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Descrição</th>
                  <th>Planta</th>
                  <th>Preço</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {enterprise.units.map((unit) => (
                  <tr key={unit.id}>
                    <td>{unit.id}</td>
                    <td>{unit.description}</td>
                    <td>{unit.projectPlanId}</td>
                    <td>{Formatter.formatNumberToBRCurrency(unit.price)}</td>
                    <td>
                      <select
                        className={`select-status status-${units.find((u) => u.id === unit.id)?.status.getStatus()}`}
                        value={units.find((u) => u.id === unit.id)?.status.getStatus() || ''}
                        onChange={(e) => handleStatusChange(unit.id, e.target.value)}
                      >
                        {unit.status.ALL.map((status) => (
                          <option key={status} value={status}>
                            {unit.status.unitStatusMap[status]}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <Tooltip text="Editar">
                        <button
                          className="icon-button"
                          onClick={() => handleEditUnit(unit)}>
                          <Edit2 size={18} />
                        </button>
                      </Tooltip>

                      <Tooltip text="Excluir">
                        <button
                          className="icon-button"
                          onClick={() => handleDeleteUnit(unit)}>
                          <Trash2 size={18} />
                        </button>
                      </Tooltip>
                      {/* <Tooltip text="Detalhes">
                        <button className="icon-button">
                          <EyeIcon size={18} />
                        </button>
                      </Tooltip> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div></div>

      {/* Modal de Confirmação - Plantas */}
      {isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Tem certeza que deseja excluir esta planta?</h3>
            <p>Ao confirmar, essa ação não poderá ser revertida.</p>
            <div className="modal-actions">
              <button
                className="cancel-button"
                onClick={handleCancelDeletePlants}
              >
                Cancelar
              </button>
              <button
                className="confirm-button"
                onClick={confirmDeletePlants}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Confirmação - Unidades */}
      {isDeleteModalUnitOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Tem certeza que deseja excluir esta unidade?</h3>
            <p>Ao confirmar, essa ação não poderá ser revertida.</p>
            <div className="modal-actions">
              <button
                className="cancel-button"
                onClick={handleCancelDeleteUnits}
              >
                Cancelar
              </button>
              <button
                className="confirm-button"
                onClick={confirmDeleteUnits}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Sessao de adicionar etapas */}
      {/* <div className="construction-stages">
        <div className="section-header">
          <h2>Etapas de construção:</h2>
          <button className="add-button">+ Adicionar etapa</button>
        </div>
        <div className="table-box-enterprise">
          <table className="table-box-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Etapas</th>
                <th>Progresso</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {enterprise.constructionSteps.map((step) => (
                <tr key={step.id}>
                  <td>{step.name}</td>
                  <td>{step.description}</td>
                  <td>{step.steps.length}</td>
                  <td>
                    <div className="progress-wrapper">
                      <div className="progress-bar">
                        <div
                          className="progress"
                          style={{ width: `${step.progress}%` }}
                        ></div>
                      </div>
                      <span>{step.progress}%</span>
                    </div>
                  </td>
                  <td>
                    <span className={`status ${step.status}`}>
                      {step.status.getFormatted()}
                    </span>
                  </td>
                  <td>
                    <div className="actions">
                      <button className="icon-button">
                        <Edit2 size={16} />
                      </button>
                      <button className="icon-button">
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}

      {/* Sessão de adicionar fotos */}
      <div className="photos-section">
        <div className="section-header">
          <h2>Fotos:</h2>
          <button onClick={handleFileInputClick} className="add-button">
            + Adicionar foto
          </button>
          <input
            type="file"
            id="add-photo"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleAddPhoto}
          />
        </div>
        <div className="photo-gallery">
          {/* Botão anterior */}
          <button
            className="gallery-nav prev"
            onClick={() =>
              setCurrentPhotoIndex((prev) =>
                prev > 0 ? prev - 1 : enterprise.images.length - 1
              )
            }
          >
            <ChevronLeft />
          </button>

          <div className="photo-grid">
            {/* Miniaturas das imagens */}
            <div className="small-photos">
              <div className="small-photos-scroll">
                {enterprise.images.map((image, index) => (
                  <div className="thumbnail-container">
                    <img
                      src={image.path}
                      alt={`Thumbnail ${index + 1}`}
                      className={`thumbnail ${index === currentPhotoIndex ? 'active' : ''}`}
                      onClick={() => setCurrentPhotoIndex(index)}
                    />
                  </div>

                ))}
              </div>
            </div>

            {/* Imagem principal */}
            <div className="main-photo-container">
              <img
                src={enterprise.images[currentPhotoIndex]?.path}
                alt={`Photo ${currentPhotoIndex + 1}`}
                className="main-photo"
              />
              <div className="thumbnail-controls">
                <button
                  onClick={() => handleEditPhoto(currentPhotoIndex)}
                  title="Editar"
                >
                  <Edit2 size={14} />
                </button>
                <button
                  onClick={() => handleDeletePhoto(currentPhotoIndex)}
                  title="Excluir"
                >
                  <Trash2 size={14} />
                </button>
                <input
                  type="file"
                  id={`edit-photo-${currentPhotoIndex}`}
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => handleEditFile(e, currentPhotoIndex)}
                />
              </div>
            </div>
          </div>

          {/* Navegação seguinte */}
          <button
            className="gallery-nav next"
            onClick={() =>
              setCurrentPhotoIndex((prev) => prev < enterprise.images.length - 1 ? prev + 1 : 0
              )}>
            <ChevronRight />
          </button>
        </div>
      </div>

      {/* Modal para adicionar e remover tags */}
      {modalOpen && (
        <div className="modal-overlay-enterprise">
          <div className="modal-enterprise">
            <div className="modal-header-enterprise">
              <h2>{modalContent.title}</h2>
              <button
                onClick={() => setModalOpen(false)}
                className="close-button-enterprise"
              >
                <X size={24} />
              </button>
            </div>
            <div className="modal-content-enterprises">
              {modalContent.tags.map((tag, index) => (
                <span key={index} className="tag">
                  {tag}
                  <button
                    className="remove-tag"
                    onClick={() => handleRemoveTag(index)}
                  >
                    ✕
                  </button>
                </span>
              ))}
              <div className="add-tag-section">
                <input
                  type="text"
                  placeholder="Adicionar novas tags"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  className="add-tag-input"
                />
                <button onClick={handleAddTag} className="add-tag-button">
                  + Adicionar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal para adicionar novas plantas */}
      {isPlantModalOpen && (
        <div className="modal-overlay-planta">
          <div className="modal-planta-popup">
            <div className="modal-header-planta">
              <h2>Adicionar planta:</h2>
              <button
                className="close-button-planta"
                onClick={handleClosePlantModal}
              >
                ✕
              </button>
            </div>
            <p>Preencha os dados abaixo para criar uma nova planta.</p>
            <div className="divisor-enterprise"></div>
            <form className="modal-form-planta" onSubmit={handleCreatePlant}>
              <div className="form-row-planta">
                <div>
                  <label>Nome da planta:</label>
                  <input
                    type="text"
                    required
                    placeholder="Digite o nome da planta"
                    value={projectPlanForm.name}
                    onChange={(e) => setProjectPlanForm({ ...projectPlanForm, name: e.target.value })}
                  />
                </div>
                <div>
                  <label>Qtd de Quartos:</label>
                  <input
                    type="number"
                    placeholder="Digite um número"
                    value={projectPlanForm.rooms[0].quantity || ""}
                    onChange={(e) => handleInputChange(e, 0, "quantity")}
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-row-planta">
                <div>
                  <label>Qtd de Banheiros:</label>
                  <input
                    type="text"
                    placeholder="Digite um número"
                    value={projectPlanForm.rooms[1].quantity || ""}
                    onChange={(e) =>
                      setProjectPlanForm({
                        ...projectPlanForm,
                        rooms: projectPlanForm.rooms.map((room, index) =>
                          index === 1
                            ? { ...room, quantity: Number(e.target.value) }
                            : room
                        ),
                      })
                    }
                  />
                </div>
                <div>
                  <label>Qtd de Garagem:</label>
                  <input
                    type="number"
                    placeholder="Digite um número"
                    value={projectPlanForm.rooms[2].quantity || ""}
                    onChange={(e) =>
                      setProjectPlanForm({
                        ...projectPlanForm,
                        rooms: projectPlanForm.rooms.map((room, index) =>
                          index === 2
                            ? { ...room, quantity: Number(e.target.value) }
                            : room
                        ),
                      })
                    }
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-row-planta">
                <div>
                  <label>Área total m²:</label>
                  <input
                    type="number"
                    required
                    placeholder="Digite a área total"
                    value={projectPlanForm.area}
                    onChange={(e) =>
                      setProjectPlanForm({
                        ...projectPlanForm,
                        area: Number(e.target.value),
                      })
                    }
                  />
                </div>
                <div>
                  <label>Área construída m²:</label>
                  <input
                    type="number"
                    required
                    placeholder="Digite a área construída"
                    value={projectPlanForm.builtArea}
                    onChange={(e) =>
                      setProjectPlanForm({
                        ...projectPlanForm,
                        builtArea: Number(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div>
                <h2 className="step-header">Upload de Fotos:</h2>
                <div
                  className="upload-area"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDropProjectPlanImage}
                  onClick={() => document.getElementById("file-input")?.click()}
                >
                  <div className="upload-icon"></div>
                  <Upload size={24} />
                  <p className="upload-text">
                    Arraste e solte uma ou mais fotos aqui, ou clique para
                    selecionar diretamente do seu dispositivo.
                  </p>
                </div>

                <input
                  id="file-input"
                  type="file"
                  accept="image/png, image/jpeg"
                  multiple
                  onChange={(e) =>
                    e.target.files &&
                    handleProjectPlanFileChange(e.target.files)
                  }
                  style={{ display: "none" }}
                />

                <div className="upload-info">
                  <p>Formatos suportados: PNG e JPEG</p>
                  <p>Tamanho máximo da foto: 10 Mb</p>
                </div>
                <div className="divisor"></div>
                {projectPlanImage.file && (
                  <div className="image-preview-container">
                    <div className="image-preview">
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteProjectPlanImage()}
                      >
                        &times;
                      </button>
                      <div className="details">
                        <div className="image-thumbnail">
                          <img
                            src={URL.createObjectURL(projectPlanImage.file)}
                            alt={`Preview ${projectPlanImage.file.name}`}
                            className="preview-image"
                          />
                        </div>
                        <div>
                          <p className="file-name">
                            {projectPlanImage.file.name}
                          </p>
                          <p className="file-size">
                            {(projectPlanImage.file.size / 1024 / 1024).toFixed(
                              2
                            )}{" "}
                            Mb
                          </p>
                        </div>
                      </div>

                      <div className="progress-container">
                        <div className="progress-bar-img">
                          <div
                            className="progress-img"
                            style={{ width: `${projectPlanImage.progress}%` }}
                          ></div>
                        </div>
                        <span className="progress-percentage">
                          {projectPlanImage.progress}%
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-buttons-planta">
                <button
                  type="button"
                  className="cancel-button-planta"
                  onClick={handleClosePlantModal}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className={`create-button-planta ${isLoading ? "loading" : ""}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span >Criando planta...</span>
                  ) : (
                    "Criar planta"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal para editar plantas */}
      {isEditModalOpen && selectedPlan && (
        <div className="modal-overlay-planta">
          <div className="modal-planta-popup">
            <div className="modal-header-planta">
              <h2>Editar planta:</h2>
              <button
                className="close-button-planta"
                onClick={() => setIsEditModalOpen(false)}
              >
                ✕
              </button>
            </div>
            <p>Edite os dados abaixo para atualizar a planta.</p>
            <div className="divisor-enterprise"></div>
            <form className="modal-form-planta" onSubmit={handleSavePlantChanges}>
              <div className="form-row-planta">
                <div>
                  <label>Nome da planta:</label>
                  <input
                    type="text"
                    required
                    placeholder="Digite o nome da planta"
                    value={selectedPlan.name}
                    onChange={(e) =>
                      setSelectedPlan({
                        ...selectedPlan,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label>Qtd de Quartos:</label>
                  <input
                    type="text"
                    placeholder="Digite um número"
                    value={selectedPlan.rooms[0].quantity || ""}
                    onChange={(e) =>
                      setSelectedPlan({
                        ...selectedPlan,
                        rooms: selectedPlan.rooms.map((room, index) =>
                          index === 0
                            ? { ...room, quantity: Number(e.target.value) }
                            : room
                        ),
                      })
                    }
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-row-planta">
                <div>
                  <label>Qtd de Banheiros:</label>
                  <input
                    type="number"
                    placeholder="Digite um número"
                    value={selectedPlan.rooms[1].quantity || ""}
                    onChange={(e) =>
                      setSelectedPlan({
                        ...selectedPlan,
                        rooms: selectedPlan.rooms.map((room, index) =>
                          index === 1
                            ? { ...room, quantity: Number(e.target.value) }
                            : room
                        ),
                      })
                    }
                  />
                </div>
                <div>
                  <label>Qtd de Garagem:</label>
                  <input
                    type="number"
                    placeholder="Digite um número"
                    value={selectedPlan.rooms[2].quantity || ""}
                    onChange={(e) =>
                      setSelectedPlan({
                        ...selectedPlan,
                        rooms: selectedPlan.rooms.map((room, index) =>
                          index === 2
                            ? { ...room, quantity: Number(e.target.value) }
                            : room
                        ),
                      })
                    }
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-row-planta">
                <div>
                  <label>Área total m²:</label>
                  <input
                    type="number"
                    required
                    placeholder="Digite a área total"
                    value={selectedPlan.area}
                    onChange={(e) =>
                      setSelectedPlan({
                        ...selectedPlan,
                        area: Number(e.target.value),
                      })
                    }
                  />
                </div>
                <div>
                  <label>Área construída m²:</label>
                  <input
                    type="number"
                    required
                    placeholder="Digite a área construída"
                    value={selectedPlan.builtArea}
                    onChange={(e) =>
                      setSelectedPlan({
                        ...selectedPlan,
                        builtArea: Number(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>

              {/* Upload de fotos */}
              <h2 className="step-header">Fotos:</h2>
              <input
                id="file-input"
                type="file"
                accept="image/png, image/jpeg"
                multiple
                onChange={(e) =>
                  e.target.files &&
                  handleProjectPlanFileChange(e.target.files)
                }
                style={{ display: "none" }}
              />

              {/* Fotos adicionadas */}
              <div className="divisor-enterprise"></div>
              {selectedPlan.images.length > 0 && (
                <div className="image-preview-container">
                  {selectedPlan.images.map((image, index) => (
                    <div key={index} className="image-preview">
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteProjectPlanImage()}
                      >
                        &times;
                      </button>
                      <div className="details">
                        <div className="image-thumbnail">
                          <img
                            src={image.imageLink}
                            alt={`Preview ${image.imageLink}`}
                            className="preview-image"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <div className="form-buttons-planta">
                <button
                  type="button"
                  className="cancel-button-planta"
                  onClick={() => setIsEditModalOpen(false)}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className={`create-button-planta ${isLoading ? "loading" : ""}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="spinner">Salvando planta...</span>
                  ) : (
                    "Salvar alterações"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal para adicionar novas unidades */}
      {isUnitModalOpen && (
        <div className="modal-overlay-unidade">
          <div className="modal-unidade">
            <div className="modal-header-unidade">
              <h2>Adicionar unidade:</h2>
              <button
                className="close-button-unidade"
                onClick={handleCloseUnitModal}
              >
                ✕
              </button>
            </div>
            <p>Preencha os dados abaixo para criar uma nova unidade.</p>
            <div className="divisor-enterprise"></div>
            <form className="modal-form-unidade" onSubmit={handleCreateUnit}>
              <div className="form-row-unidade">
                <div>
                  <label>Descrição:</label>
                  <input
                    type="text"
                    required
                    placeholder="Descrição da unidade"
                    value={unitForm.description}
                    onChange={(e) =>
                      setUnitForm({ ...unitForm, description: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label>Preço:</label>
                  <input
                    type="text"
                    required
                    placeholder="R$"
                    value={
                      unitForm.price !== 0
                        ? Formatter.formatNumberToBRCurrency(unitForm.price)
                        : ""
                    }
                    onChange={(e) => {
                      const value = Formatter.clearSymbolsAndLetters(
                        e.target.value
                      );
                      setUnitForm({
                        ...unitForm,
                        price: Number(value),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-row-unidade">
                <div>
                  <label>Planta:</label>
                  <select
                    id="planta"
                    required
                    value={unitForm.projectPlanId}
                    onChange={(e) =>
                      setUnitForm({
                        ...unitForm,
                        projectPlanId: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Selecione a planta:
                    </option>
                    {enterprise.projectPlans.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>Blocos (Opcional):</label>
                  <input type="text" placeholder="Digite um numero" />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div>
                <label>Status:</label>
                <select
                  id="status"
                  required
                  value={unitForm.status.getStatus()}
                  onChange={(e) => {
                    setUnitForm({
                      ...unitForm,
                      status: new UnitStatus(e.target.value),
                    });
                  }}
                >
                  <option value="" disabled>
                    Selecione o status:
                  </option>
                  {unitStatus.ALL.map((plan) => (
                    <option key={plan} value={plan}>
                      {unitStatus.unitStatusMap[plan]} { }
                    </option>
                  ))}
                </select>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-buttons-unidade">
                <button
                  type="button"
                  className="cancel-button-unidade"
                  onClick={handleCloseUnitModal}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className={`create-button-unidade ${isLoading ? "loading" : ""}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span>Criando unidade...</span>
                  ) : (
                    "Criar unidade"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Modal para editar unidades */}
      {isEditUnitModalOpen && selectedUnit && (
        <div className="modal-overlay-unidade">
          <div className="modal-unidade">
            <div className="modal-header-unidade">
              <h2>Editar unidade:</h2>
              <button
                className="close-button-unidade"
                onClick={handleCloseEditUnitModal}
              >
                ✕
              </button>
            </div>
            <p>Edite os dados abaixo para atualizar a unidade.</p>
            <div className="divisor-enterprise"></div>
            <form className="modal-form-unidade" onSubmit={handleSaveUnitChanges}>
              <div className="form-row-unidade">
                <div>
                  <label>Nome da unidade:</label>
                  <input
                    type="text"
                    required
                    placeholder="Digite o nome da unidade"
                    value={unitForm.description}
                    onChange={(e) =>
                      setUnitForm({ ...unitForm, description: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label>Preço:</label>
                  <input
                    type="text"
                    required
                    placeholder="R$"
                    value={
                      unitForm.price !== 0
                        ? Formatter.formatNumberToBRCurrency(unitForm.price)
                        : ""
                    }
                    onChange={(e) => {
                      const value = Formatter.clearSymbolsAndLetters(
                        e.target.value
                      );
                      setUnitForm({
                        ...unitForm,
                        price: Number(value),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-row-unidade">
                <div>
                  <label>Planta:</label>
                  <select
                    id="planta"
                    required
                    value={unitForm.projectPlanId}
                    onChange={(e) =>
                      setUnitForm({
                        ...unitForm,
                        projectPlanId: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Selecione a planta:
                    </option>
                    {enterprise.projectPlans.map((plan) => (
                      <option key={plan.id} value={plan.id}>
                        {plan.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <div>
                    <label>Blocos (Opcional):</label>
                    <input type="text" placeholder="Digite um numero" />
                  </div>
                </div>
              </div>
              <div className="divisor-enterprise"></div>
              <div>
                <label>Status:</label>
                <select
                  id="status"
                  required
                  value={unitForm.status.getStatus()}
                  onChange={(e) => {
                    setUnitForm({
                      ...unitForm,
                      status: new UnitStatus(e.target.value),
                    });
                  }}
                >
                  <option value="" disabled>
                    Selecione o status:
                  </option>
                  {unitStatus.ALL.map((plan) => (
                    <option key={plan} value={plan}>
                      {unitStatus.unitStatusMap[plan]} { }
                    </option>
                  ))}
                </select>
              </div>
              <div className="divisor-enterprise"></div>
              <div className="form-buttons-unidade">
                <button
                  type="button"
                  className="cancel-button-unidade"
                  onClick={handleCloseEditUnitModal}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className={`create-button-planta ${isLoading ? "loading" : ""}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span >Salvando unidade...</span>
                  ) : (
                    "Salvar alterações"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default EnterprisesDetails;
