import React, { useEffect } from 'react';
import '../../styles/components/_home.scss';
import { FaDollarSign, FaBuilding, FaChartLine, FaHandshake, FaEye, FaChartBar, FaMoneyBillAlt } from 'react-icons/fa';

const Home = () => {
  const animateCounter = (id: string, targetValue: number, duration: number) => {
    const element = document.getElementById(id);
    if (!element) return;

    const startValue = 0;
    const increment = targetValue / (duration * 40);

    let currentValue = startValue;
    const timer = setInterval(() => {
      currentValue += increment;
      if (currentValue >= targetValue) {
        currentValue = targetValue;
        clearInterval(timer);
      }
      element.textContent = Math.floor(currentValue).toString();
    }, 1000 / 60);
  };

  useEffect(() => {
    animateCounter('vendas', 132, 2);
    animateCounter('empreendimentos', 51, 2);
    animateCounter('corretores', 65, 2);
    animateCounter('visitas', 2120, 2);
    animateCounter('leads', 201, 2);
    animateCounter('bouncerate', 75, 2);
    animateCounter('valor', 925, 2); // Novo quadrado
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard__card vendas">
        <div className="dashboard__icon">
          <FaDollarSign />
        </div>
        <div className="dashboard__info">
          <h3 id="vendas">000</h3>
          <p>Vendas mensais</p>
          <span className="positive">+9.21%</span>
        </div>
      </div>

      <div className="dashboard__card empreendimentos">
        <div className="dashboard__icon1">
          <FaBuilding />
        </div>
        <div className="dashboard__info">
          <h3 className='number1' id="empreendimentos">000</h3>
          <p>Empreendimentos</p>
          <span className="positive">+2.43%</span>
        </div>
      </div>

      <div className="dashboard__card corretores">
        <div className="dashboard__icon">
          <FaChartLine />
        </div>
        <div className="dashboard__info">
          <h3 id='corretores'>000</h3>
          <p>Produção dos Corretores</p>
          <span className="positive">+3.23%</span>
        </div>
      </div>

      <div className="dashboard__card visualizacoes">
        <div className="dashboard__icon1">
          <FaEye />
        </div>
        <div className="dashboard__info">
          <h3 className='number1' id='leads'>000</h3>
          <p>Leads</p>
          <span className="positive">+9.22%</span>
        </div>
      </div>

      <div className="dashboard__card visitas">
        <div className="dashboard__icon">
          <FaHandshake />
        </div>
        <div className="dashboard__info">
          <h3 id='visitas'>000</h3>
          <p>Visitas</p>
          <span className="negative">-3.21%</span>
        </div>
      </div>

      <div className="dashboard__card bouncerate">
        <div className="dashboard__icon1">
          <FaChartBar />
        </div>
        <div className="dashboard__info">
          <h3 className='number1' id='bouncerate'>000</h3>
          <p>Bouncerate</p>
          <span className="negative">-2.12%</span>
        </div>
      </div>

      {/* Novo quadrado */}
      <div className="dashboard__card valor">
        <div className="dashboard__icon">
          <FaMoneyBillAlt />
        </div>
        <div className="dashboard__info">
          <h3 id="valor">000</h3>
          <p>Valor de vendas (R$)</p>
          <span className="positive">+9.34%</span>
        </div>
      </div>
    </div>
  );
};

export default Home;
