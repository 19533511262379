import PropertyQuiz from "../entities/valueObjects/ownPropertyQuiz";

export default class PropertyQuizDTO {
    static fromJson(json: any): PropertyQuiz {
        return new PropertyQuiz(
            json.value,
            json.isOwned,
            json.isThirdParty,
            json.isFinanced,
            json.wantsRefinance,
            json.refinanceValue,
            json.thirdPartyDocument
        )
    }
}