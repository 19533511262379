import FgtsQuiz from "../entities/valueObjects/fgtsQuiz";

export default class FgtsQuizDTO {
    static fromJson(json: any): FgtsQuiz {
        return new FgtsQuiz(
            json.balance,
            json.hasAnniversaryWithdrawal,
            json.useAsPayment,
            json.useForAmortization,
            json.hasWithdrawalTwo,
            json.balanceTwo,
            json.hasAnniversaryWithdrawalTwo,
            json.useAsPaymentTwo,
            json.thirdPartyDocument
        )
    }
}