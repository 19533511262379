import React, { useState, useEffect, useCallback } from 'react';
import '../styles/components/_faq.scss';
import { Trash2, Edit2, X } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Tooltip from './tootip/Tootip';
import FAQProvider from '../infra/provider/faqProvider';
import CommonQuestion from '../domain/entities/commonQuestion';

const FAQ = () => {
  const [faqs, setFaqs] = useState<CommonQuestion[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState<'add' | 'edit' | 'delete'>('add');
  const [currentFAQ, setCurrentFAQ] = useState<CommonQuestion | null>(null);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const faqProvider = FAQProvider.getInstance();

  const getCommonQuestions = useCallback(async () => {
    try {
      const response = await faqProvider.getCommonQuestions();
      setFaqs(response);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar propostas");
    } finally {
    }
  }, [faqProvider]);

  useEffect(() => {
    getCommonQuestions();
  }, [getCommonQuestions]);

  const filteredFAQs = faqs.filter(
    faq =>
      faq.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openModal = (type: 'add' | 'edit' | 'delete', faq?: CommonQuestion) => {
    setModalType(type);
    setCurrentFAQ(faq || null);
    setNewQuestion(faq ? faq.name : '');
    setNewAnswer(faq ? faq.answer : '');
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentFAQ(null);
    setNewQuestion('');
    setNewAnswer('');
  };

  const handleCreateFaq = async (commonQuestion: CommonQuestion) => {
    try {
      await faqProvider.createCommonQuestion(commonQuestion!);
      toast.success("Pergunta criada com sucesso!");
    }
    catch (error) {
      toast.error("Não foi possível criar a pergunta.");
      return;
    }
    finally {
      getCommonQuestions();
    }
  };

  const handleUpdateFaq = async (faqToUpdate: CommonQuestion) => {
    try {
      await faqProvider.updateCommonQuestion(faqToUpdate);
      toast.success("Pergunta atualizada com sucesso!");
    }
    catch (error) {
      toast.error("Não foi possível atualizar a pergunta.");
      return;
    }
    finally {
      getCommonQuestions();
    }
  };

  const handleDeleteFaq = async (faqId: string) => {
    try {
      await faqProvider.deleteCommonQuestion(faqId);
      toast.success("Pergunta deletada com sucesso!");
    }
    catch (error) {
      toast.error("Não foi possível deletar a pergunta.");
      return;
    }
    finally {
      getCommonQuestions();
    }
  };

  const handleSave = () => {
    if (modalType === 'add') {
      const newFAQ: CommonQuestion = {
        id: "221",
        name: newQuestion,
        question: newQuestion,
        answer: newAnswer,
        type: "1",
        helpfulVotes: 0
      };
      setFaqs([...faqs, newFAQ]);
      handleCreateFaq(newFAQ);
    }
    else if (modalType === 'edit' && currentFAQ) {
      const updatedFAQs = faqs.map(faq => faq.id === currentFAQ.id ? { ...faq, question: newQuestion, answer: newAnswer } : faq
      );
      setFaqs(updatedFAQs);
      handleUpdateFaq(updatedFAQs.find(faq => faq.id === currentFAQ.id)!);
    }
    closeModal();
  };

  const handleDelete = () => {
    if (currentFAQ) {
      handleDeleteFaq(currentFAQ.id);
      closeModal();
    }
  };

  const clearFilters = () => {
    setSearchTerm('');
  };

  return (
    <div className="faq-manager-main">
      <button className="add-button-plan-viab" onClick={() => openModal('add')}>
        + Adicionar pergunta
      </button>
      <div className="filters-container">
        <input
          type="text"
          placeholder="Buscar perguntas e respostas"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input-faq"
        />
        <button className="clear-filters-button-enterprise" onClick={clearFilters}>
          Limpar filtro
        </button>
      </div>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Pergunta</th>
            <th>Resposta</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredFAQs.map((faq) => (
            <tr key={faq.id}>
              <td>{faq.id}</td>
              <td>{faq.name}</td>
              <td>{faq.answer}</td>
              <td>
                <Tooltip text="Editar">
                  <button className="edit-button-faq" onClick={() => openModal('edit', faq)}>
                    <Edit2 size={20} />
                  </button>
                </Tooltip>
                <Tooltip text="Excluir">
                  <button className="delete-button-faq" onClick={() => openModal('delete', faq)}>
                    <Trash2 size={20} />
                  </button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {modalOpen && (
        <div className="modal-overlay-faq">
          <div className="modal-faq">
            <h2>
              {modalType === 'add'
                ? 'Adicionar pergunta e resposta:'
                : modalType === 'edit'
                  ? 'Editar pergunta e resposta:'
                  : 'Confirmação de exclusão:'}
            </h2>
            {modalType !== 'delete' ? (
              <>
                <p>Preencha os dados abaixo para {modalType === 'add' ? 'criar uma nova' : 'editar a'} pergunta e resposta.</p>
                <input
                  type="text"
                  placeholder="Pergunta:"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Resposta:"
                  value={newAnswer}
                  onChange={(e) => setNewAnswer(e.target.value)}
                />
                <div className="modal-buttons-faq">
                  <button onClick={closeModal}>Cancelar</button>
                  <button onClick={handleSave}>Confirmar</button>
                </div>
              </>
            ) : (
              <>
                <p>Você tem certeza que deseja excluir essa pergunta? Esta ação não pode ser desfeita.</p>
                <div className="modal-buttons-faq">
                  <button onClick={closeModal}>Cancelar</button>
                  <button onClick={handleDelete}>Excluir</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default FAQ;

