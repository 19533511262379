import CommonQuestion from "../entities/commonQuestion";

export default class CommonQuestionDTO {
    static fromJson(json: any): CommonQuestion {
        return new CommonQuestion(
            json.id,
            json.name,
            json.name,
            json.answer,
            json.type,
            json.helpfulVotes,
        )
    }
}