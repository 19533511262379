import Enterprise from "./enterprise";
import Lead from "./lead";
import ProjectPlan from "./projectPlan";
import ProposalDocument from "./proposalDocument";
import ProposalHistory from "./proposalHistory";
import { ProposalObservation } from "./proposalObservation";
import Unit from "./unit";
import User from "./user";
import BusinessQuiz from "./valueObjects/businessQuiz";
import Currency from "./valueObjects/currency";
import FgtsQuiz from "./valueObjects/fgtsQuiz";
import FreelancerQuiz from "./valueObjects/freelancerQuiz";
import LoanQuiz from "./valueObjects/loanQuiz";
import PropertyQuiz from "./valueObjects/ownPropertyQuiz";
import PaymentSignal from "./valueObjects/paymentSignal";
import PreApprovedValue from "./valueObjects/preApprovedValue";
import ProposalStatus from "./valueObjects/proposalStatus";
import VehicleQuiz from "./valueObjects/vehicleQuiz";

interface ProposalParams {
  id?: string;
  entryAmount?: Currency;
  unitPrice?: Currency;
  status?: ProposalStatus;
  statusDetail?: string;
  createdBy?: User;
  createdAt?: number;
  lead?: Lead;
  unit?: Unit;
  projectPlan?: ProjectPlan;
  enterprise?: Enterprise;
  preApprovedValues?: any[];
  fgtsQuiz?: FgtsQuiz | null;
  propertyQuiz?: PropertyQuiz | null;
  vehicleQuiz?: VehicleQuiz[];
  loanQuiz?: LoanQuiz | null;
  freelancerQuiz?: FreelancerQuiz | null;
  businessQuiz?: BusinessQuiz | null;
  paymentSignalMethods?: PaymentSignal[];
  observation?: string;
  observations?: ProposalObservation[];
  documents?: any[];
  history?: ProposalHistory[];
}

export class Proposal {
  public id: string;
  public entryAmount: Currency;
  public unitPrice: Currency;
  public status: ProposalStatus;
  public statusDetail: string;
  public createdBy: User;
  public createdAt: number;
  public lead: Lead;
  public unit: Unit;
  public projectPlan: ProjectPlan;
  public enterprise: Enterprise;
  public preApprovedValues: PreApprovedValue[];
  public fgtsQuiz: FgtsQuiz | null;
  public propertyQuiz: PropertyQuiz | null;
  public vehicleQuiz: VehicleQuiz[];
  public loanQuiz: LoanQuiz | null;
  public freelancerQuiz: FreelancerQuiz | null;
  public businessQuiz: BusinessQuiz | null;
  public paymentSignalMethods: PaymentSignal[];
  public observation: string;
  public observations: ProposalObservation[];
  public documents: ProposalDocument[];
  public history: ProposalHistory[];

  constructor({
    id = "",
    entryAmount = new Currency(),
    unitPrice = new Currency(),
    status = new ProposalStatus(""),
    statusDetail = "",
    createdBy = new User(),
    createdAt = 0,
    lead = new Lead(),
    unit = new Unit(),
    projectPlan = new ProjectPlan(),
    enterprise = new Enterprise(),
    preApprovedValues = [],
    fgtsQuiz = null,
    propertyQuiz = null,
    vehicleQuiz = [],
    loanQuiz = null,
    freelancerQuiz = null,
    businessQuiz = null,
    paymentSignalMethods = [],
    observation = "",
    observations = [],
    documents = [],
    history = [],
  }: ProposalParams = {}) {
    this.id = id;
    this.entryAmount = entryAmount;
    this.unitPrice = unitPrice;
    this.status = status;
    this.statusDetail = statusDetail;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.lead = lead;
    this.unit = unit;
    this.projectPlan = projectPlan;
    this.enterprise = enterprise;
    this.preApprovedValues = preApprovedValues;
    this.fgtsQuiz = fgtsQuiz;
    this.propertyQuiz = propertyQuiz;
    this.vehicleQuiz = vehicleQuiz;
    this.loanQuiz = loanQuiz;
    this.freelancerQuiz = freelancerQuiz;
    this.businessQuiz = businessQuiz;
    this.paymentSignalMethods = paymentSignalMethods;
    this.observation = observation;
    this.observations = observations;
    this.documents = documents;
    this.history = history;
  }
}
