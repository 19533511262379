import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DrawerFilter from '../DrawerFilter';
import { useLocation, Link } from 'react-router-dom';
import '../../styles/components/_header.scss';

const Header: React.FC<{ title: string }> = ({ title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const generateBreadcrumb = () => {
    const pathParts = location.pathname.split('/').filter(part => part);
    const breadcrumbParts = [];

    breadcrumbParts.push(
      <span key="home">
        HomeCorp
      </span>
    );

    pathParts.forEach((part, index) => {
      const label = part.charAt(0).toUpperCase() + part.slice(1);
      breadcrumbParts.push(
        <React.Fragment key={index}>
          <FontAwesomeIcon icon={faChevronRight} className="icon-arrow" />
          <span>{label}</span>
        </React.Fragment>
      );
    });

    return breadcrumbParts;
  };

  // Defina a mensagem baseada no título
  const getTitleMessage = () => {
    if (title.toLowerCase() === 'home') {
      return 'Visualize suas informações';
    } else if (title.toLowerCase() === 'propostas') {
      return 'Visualize sua lista de propostas';
    } else if (title.toLowerCase() === 'cadastro') {
      return 'Cadastre suas informações';
    } else {
      return `Visualize sua lista de: ${title.toLowerCase()}`;
    }
  };

  return (
    <>
      <header className="header-main">
        <div className="header-content-main">
          <div className="breadcrumb">
            {generateBreadcrumb()}
          </div>
          <h2 className='title-header-main'>{getTitleMessage()}</h2>
        </div>
      </header>

      <DrawerFilter isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </>
  );
};


export default Header;