import PropertyQuiz from "./ownPropertyQuiz";

export default class VehicleQuiz extends PropertyQuiz {
    constructor(
        value: number | undefined = undefined,
        isOwned: boolean | undefined = undefined,
        isThirdParty: boolean | undefined = undefined,
        isFinanced: boolean | undefined = undefined,
        wantsRefinance: boolean | undefined = undefined,
        refinanceValue: number | undefined = undefined,
        useAsPayment: boolean | undefined = undefined,
        public vehicleType: string | undefined = undefined,
        public year: number | undefined = undefined,
        public model: string | undefined = undefined,
        public manufacturer: string | undefined = undefined,
        public km: number | undefined = undefined,
        public thirdPartyDocument: string | undefined = undefined,
    ) {
        super(
            value,
            isOwned,
            isThirdParty,
            isFinanced,
            wantsRefinance,
            refinanceValue,
            thirdPartyDocument,
            useAsPayment
        );
    }
}