import FreelancerQuiz from "../entities/valueObjects/freelancerQuiz";

export default class FreelancerQuizDTO {
    static fromJson(json: any): FreelancerQuiz {
        return new FreelancerQuiz(
            json.profession,
            json.hasDebt,
            json.debtValue,
            json.hasOverdraft,
            json.hasGuaranteedAccount,
            json.hasOthers,
            json.overdraftValue,
            json.guaranteedAccountValue,
            json.othersValue,
        )
    }
}