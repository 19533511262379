import React, { useState, useEffect, useCallback, useRef } from "react";
import { Trash2, Edit2, X } from 'lucide-react';
import "../../styles/components/_scheduling.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "../../components/tootip/Tootip";
import ScheduleVisitProvider from "../../infra/provider/scheduleVisitProvider";
import { debounce } from "lodash";
import { Formatter } from "../../utils/formatter";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import Enterprise from "../../domain/entities/enterprise";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";

interface TableData {
  id: string;
  createdAt: string;
  date: string;
  time: string;
  leadName: string;
  phone: string;
  enterpriseName: string;
}

export function Scheduling() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedEnterprise, setSelectedLocation] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [data, setData] = useState<TableData[]>([]);
  const isFetching = useRef(false);
  const isFetchEnterprise = useRef(false);

  const [enterprises, setEnterprises] = useState<Enterprise[]>([]);

  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [showEditModal, setShowEditModal] = useState(false);
  // const [selectedVisit, setSelectedVisit] = useState<TableData | null>(null);
  // const [editForm, setEditForm] = useState({
  //   date: "",
  //   time: "",
  // });

  const scheduleVisitsProvider = ScheduleVisitProvider.getInstance();
  const enterpriseProvider = EnterpriseProvider.getInstance();

  const getEnterprises = async () => {
    if (isFetchEnterprise.current) return;
    isFetchEnterprise.current = true;
    const enterprises = await enterpriseProvider.getEnterprisesName();
    setEnterprises(enterprises);
    isFetchEnterprise.current = false;
  };

  const getScheduledVisits = useCallback(
    async (
      pageNumber = 1,
      searchTerm = "",
      selectedDate = "",

      selectedEnterprise = ""
    ) => {
      if (isFetching.current) return;
      isFetching.current = true;
      const search = new ListSearch({
        searchTerm: searchTerm,
        status: selectedStatus,
        orderBy: "createdAt",
        limit: itemsPerPage,
        offset: (pageNumber - 1) * itemsPerPage,
        fromDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
        toDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
        enterpriseId: Number(selectedEnterprise),
      });
      try {
        const response =
          await scheduleVisitsProvider.getScheduledVisits(search);
        const mappedData = response.scheduleVisits.map((visit) => ({
          id: visit.id,
          createdAt: Formatter.timestampToDateBR(visit.createdAt),
          date: Formatter.timestampToDateBR(visit.date, false),
          time: Formatter.timestampToTime(visit.date),
          leadName: visit.requester ? visit.requester.name : "",
          phone: visit.requester ? visit.requester.phone : "",
          enterpriseName: visit.enterpriseName,
        }));
        setData(mappedData);
        setTotalItems(response.totalItems);
      } catch (error) {
        toast.error("Erro ao carregar visitas agendadas");
        console.error(error);
      } finally {
        isFetching.current = false;
      }
    },
    [scheduleVisitsProvider, itemsPerPage, selectedStatus]
  );

  useEffect(() => {
    getScheduledVisits(currentPage);
    getEnterprises();
  }, [itemsPerPage, currentPage, getScheduledVisits]);

  const debouncedSearchTerm = useRef(
    debounce((term) => {
      getScheduledVisits(1, term, selectedDate, selectedEnterprise);
    }, 600)
  ).current;

  const debouncedDate = useRef(
    debounce((date) => {
      getScheduledVisits(1, searchTerm, date, selectedEnterprise);
    }, 600)
  ).current;

  const debouncedLocation = useRef(
    debounce((location) => {
      getScheduledVisits(1, searchTerm, selectedDate, location);
    }, 600)
  ).current;

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    debouncedSearchTerm(searchTerm);
  };

  const handleSearchDateChange = (date: string) => {
    setSelectedDate(date);
    debouncedDate(date);
  };

  const handleSearchLocationChange = (location: string) => {
    setSelectedLocation(location);
    debouncedLocation(location);
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedDate("");
    setSelectedLocation("");
    getScheduledVisits(1, "", "", "");
  };

  const formatphone = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, "");
    if (cleaned.length === 13) {
      return `+${cleaned.slice(0, 2)} (${cleaned.slice(2, 4)}) ${cleaned.slice(4, 8)}-${cleaned.slice(8)}`;
    } else if (cleaned.length === 11) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
    }
    return phone;
  };

  return (
    <div className="container-scheduling">
      <div className="search-section-scheduling">
        <input
          type="text"
          placeholder="Busque por um nome, empreendimento, celular, etc..."
          className="search-input-scheduling"
          value={searchTerm}
          onChange={(e) => handleSearchTermChange(e.target.value)}
        />
        <input
          type="date"
          className="search-input-scheduling"
          placeholder="Selecione a data"
          value={selectedDate}
          onChange={(e) => handleSearchDateChange(e.target.value)}
        />
        <select
          className="search-select-scheduling"
          value={selectedEnterprise}
          onChange={(e) => handleSearchLocationChange(e.target.value)}
        >
          <option value="">Selecione um empreendimento</option>
          {enterprises.map((enterprise) => (
            <option key={enterprise.id} value={enterprise.id}>
              {enterprise.name}
            </option>
          ))}
        </select>
        <button
          className="clear-filters-button-enterprise"
          onClick={handleClearFilters}
        >
          Limpar Filtros
        </button>
      </div>
      <table className="table-scheduling">
        <thead>
          <tr>
            <th>Data da visita</th>
            <th>Horário</th>
            <th>Nome do lead</th>
            <th>Empreendimento</th>
            <th>Celular</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {data.map((visit) => (
            <tr key={visit.id}>
              <td>{visit.date}</td>
              <td>{visit.time}</td>
              <td>{visit.leadName}</td>
              <td>{visit.enterpriseName}</td>
              <td>{formatphone(visit.phone)}</td>
              <td>
                <div className="actions-scheduling">
                  {/* <Tooltip text="Editar">
                    <button
                      className="icon-button-scheduling"
                      onClick={() => handleEdit(visit)}
                    >
                      <Edit2 size={16} />
                    </button>
                  </Tooltip> */}
                  {/* <Tooltip text="Excluir">
                    <button
                      className="icon-button-scheduling"
                      onClick={() => handleDelete(visit)}
                    >
                      <Trash2 size={16} />
                    </button>
                  </Tooltip> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ToastContainer />
    </div>
  );
}

export default Scheduling;

