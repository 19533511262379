import React, { useState, useRef, KeyboardEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/components/_token.scss';
import logo from '../../assets/images/homecorp-logo.png';
import header from '../../assets/images/header.png';
import AuthProvider from '../../infra/provider/authProvider';
import PopupError from '../popup/PopupError';
import { toast } from 'react-toastify';

enum ResetStage {
  EMAIL,
  CODE,
  NEW_PASSWORD
}

const PasswordReset = () => {
  const navigate = useNavigate();
  const authProvider = AuthProvider.getInstance();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(['', '', '', '', '']);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [stage, setStage] = useState<ResetStage>(ResetStage.EMAIL);
  const [error, setError] = useState('');
  const [popup, setPopup] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSendCode = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authProvider.forgotPassword(email);
      setStage(ResetStage.CODE);
    } catch (err: any) {
      setError(err.message);
      setPopup(true);
    }
  };

  const handleVerifyCode = async (e: React.FormEvent) => {
    e.preventDefault();
    const fullCode = code.join('');
    if (fullCode.length !== 5) {
      setError('Por favor, preencha todos os dígitos do código.');
      setPopup(true);
      return;
    }
    try {
      setStage(ResetStage.NEW_PASSWORD);
    } catch (err: any) {
      setError(err.message);
      setPopup(true);
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('As senhas não coincidem.');
      setPopup(true);
      return;
    }
    try {
      await authProvider.resetPassword(email, newPassword, code.join(''));
      navigate('/login', { state: { message: 'Senha redefinida com sucesso. Por favor, faça login com sua nova senha.' } });
    } catch (err: any) {
      setError(err.message);
      setPopup(true);
      toast.error(err.message);
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value !== '' && index < 4) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && code[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const renderStageContent = () => {
    switch (stage) {
      case ResetStage.EMAIL:
        return (
          <>
            <h1>Vamos enviar um código para o seu e-mail!</h1>
            <p className='forget-pass'>Para redefinir sua senha, preencha seu e-mail abaixo.</p>
            <form onSubmit={handleSendCode}>
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                type="submit"
                disabled={isLoading}
                className={isLoading ? 'loading' : ''}
              >
                {isLoading ? (
                  <>
                    <div className="spinner"></div>
                    <span>Enviando código...</span>
                  </>
                ) : (
                  "Enviar código"
                )}
              </button>

            </form>
          </>
        );
      case ResetStage.CODE:
        return (
          <>
            <h1>Digite o código enviado</h1>
            <p className='forget-pass'>Insira o código de 5 dígitos que enviamos para o seu e-mail.</p>
            <form onSubmit={handleVerifyCode}>
              <label htmlFor="code" className='code-pass'>Código de 5 dígitos:</label>
              <div className="code-inputs">
                {code.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    required
                  />
                ))}
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className={isLoading ? 'loading' : ''}
              >
                {isLoading ? (
                  <>
                    <div className="spinner"></div>
                    <span>Verificando código...</span>
                  </>
                ) : (
                  "Verificar código"
                )}
              </button>
            </form>
          </>
        );
      case ResetStage.NEW_PASSWORD:
        return (
          <>
            <h1>Crie sua nova senha</h1>
            <p className='forget-pass'>Por favor, insira e confirme sua nova senha abaixo.</p>
            <form onSubmit={handleResetPassword}>
              <label htmlFor="newPassword">Nova senha:</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                minLength={8}
              />
              <label htmlFor="confirmPassword">Confirme a nova senha:</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                minLength={8}
              />
              <button type="submit">Redefinir senha</button>
            </form>
          </>
        );
    }
  };

  return (
    <div className="password-reset-container">
      <div className="login-image">
        <img src={header} alt="Construtech" />
      </div>
      <div className="password-reset-form">
        <div className="form-container">
          <img src={logo} alt="Homecorp logo" className="logo" />
          {renderStageContent()}
          {stage === ResetStage.EMAIL && (
            <Link to="/login" className="login-link">Já possui login? Clique aqui</Link>
          )}
        </div>
      </div>
      {popup && <PopupError message={error} onClose={() => setPopup(false)} />}
    </div>
  );
};

export default PasswordReset;

