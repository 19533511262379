import Ownership from "../entities/valueObjects/ownership";
import PaymentSignal from "../entities/valueObjects/paymentSignal";
import SignalMethod from "../entities/valueObjects/signalMethod";

export default class PaymentSignalDTO {
  static fromJson(json: any): PaymentSignal {
    return new PaymentSignal(
      json.hasEntry,
      new SignalMethod(json.method),
      json.amount,
      json.date,
      json.installments,
      json.ownership ? new Ownership(json.ownership) : undefined,
      json.thirdPartyDocument
    );
  }

  static toJson(paymentSignal: PaymentSignal) {
    return {
      method: paymentSignal.method.getMethod(),
      amount: paymentSignal.amount,
      date: paymentSignal.date,
      installments: paymentSignal.installments,
      ownership: paymentSignal.ownership?.getMethod(),
      thirdPartyDocument: paymentSignal.thirdPartyDocument,
    };
  }
}
