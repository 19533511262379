import CommonQuestionDTO from "../../domain/dto/commonQuestionDTO";
import CommonQuestion from "../../domain/entities/commonQuestion";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class FAQRepository {
    private static instance: FAQRepository | null = null

    constructor(
        private http: Http
    ) {}

    static getInstance(http: Http): FAQRepository {
        if (!FAQRepository.instance) {
            FAQRepository.instance = new FAQRepository(http)
        }
        return FAQRepository.instance
    }

    async createCommonQuestion(commonQuestion: CommonQuestion) {
        const request = new HttpRequest(
            ApiRoutes.commonQuestions,
            commonQuestion,
        );

        return await this.http.post<CommonQuestion>(request);
    }

    async getCommonQuestions(): Promise<CommonQuestion[]> {
        const request = new HttpRequest(
            ApiRoutes.commonQuestions,
        );

        const response = await this.http.get<CommonQuestion[]>(request);
        return response.map((commonQuestion) => CommonQuestionDTO.fromJson(commonQuestion));
    }

    async getCommonQuestionById(id: string): Promise<CommonQuestion> {
        const request = new HttpRequest(
            `${ApiRoutes.commonQuestions}/${id}`,
        );

        const response = await this.http.get<CommonQuestion>(request);
        return CommonQuestionDTO.fromJson(response);
    }

    async updateCommonQuestion(commonQuestion: CommonQuestion) {
        const request = new HttpRequest(
            `${ApiRoutes.commonQuestions}/${commonQuestion.id}`,
            commonQuestion,
        );

        return await this.http.put<CommonQuestion>(request);
    }

    async deleteCommonQuestion(commonQuestionId: string) {
        const request = new HttpRequest(
            `${ApiRoutes.commonQuestions}/${commonQuestionId}`,
        );

        return await this.http.delete(request);
    }
}