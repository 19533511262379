import BusinessQuiz from "../entities/valueObjects/businessQuiz";

export default class BusinessQuizDTO {
    static fromJson(json: any): BusinessQuiz {
        return new BusinessQuiz(
            json.cnpj,
            json.companyType,
            json.lastThreeMonthsRevenue,
            json.cardsRevenue,
            json.currentAcquirer,
            json.hasSpecialCheck,
            json.hasGuaranteedAccount,
            json.hasOtherLoans,
            json.debtValue,
            json.debtValue,
            json.debitRate,
            json.debitRateAtSight,
            json.creditRateAtSight,
            json.creditRateInstallments,
            json.creditRateInstallmentsTwo,
            json.specialCheckValue,
            json.guaranteedAccountValue,
            json.otherLoansValue,
        )

        /* constructor(
        public cnpj: string = "",
        public companyType: string = "",
        public hasSpecialCheck: boolean = false,
        public hasGuaranteedAccount: boolean = false,
        public hasOtherLoans: boolean = false,
        public debtValue: Currency = new Currency(),
        public debitRate: number = 0,
        public debitRateAtSight: number | null = null,
        public creditRateAtSight: number | null = null,
        public creditRateInstallments: number | null = null,
        public creditRateInstallmentsTwo: number | null = null,
    ) {} */
    }
}