export default class FreelancerQuiz {
    constructor(
        public profession: string | undefined = undefined,
        public hasDebt: boolean | undefined = undefined,
        public debtValue: number | undefined = undefined,
        public hasOverdraft: boolean | undefined = undefined,
        public hasGuaranteedAccount: boolean | undefined = undefined,
        public hasOthers: boolean | undefined = undefined,
        public overdraftValue?: number,
        public guaranteedAccountValue?: number,
        public othersValue?: number
    ) {}
}
